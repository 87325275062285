/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";

// import { FaPlus } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// import { AiFillEdit, AiFillEye } from "react-icons/ai";
// import { FiDelete, FiEdit } from "react-icons/fi";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
// import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import { Input, Label, Spinner, Button } from "reactstrap";
import { formatNumber } from "redux/action/api";
import { year } from "helper";
import { useYear } from "useYearList";

export default function MDAReportModal({ mda_name, toggle }) {
  const years = useYear();
  const [form, setForm] = useState({
    budget_year: year,
    transaction_type: "",
  });

  const fields = [
    {
      title: "Project Description",
      custom: true,
      component: (ite) => <div> {ite.description}</div>,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => item.economic_code,
    },

    {
      title: "Economic Description",
      custom: true,
      component: (ite) => ite.economic_description,
    },

    { title: "Fund Code", custom: true, component: (ite) => ite.fund_code },
    {
      title: "Fund Description",
      custom: true,
      component: (ite) => ite.fund_description,
    },
    {
      title: "Program Code",
      custom: true,
      component: (ite) => ite.program_code,
    },
    {
      title: "Program Description",
      custom: true,
      component: (ite) => ite.program_description,
    },
    {
      title: "Functional code",
      custom: true,
      component: (ite) => ite.function_code,
    },

    { title: "Geo code", custom: true, component: (ite) => ite.geo_code },
    {
      title: "Geo Description",
      custom: true,
      component: (ite) => ite.geo_description,
    },
    {
      title: "Proposed Amount(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.budget_amount)}</div>
      ),
    },
    {
      title: "MOPB Estimate(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.mopb_amount)}</div>
      ),
    },
    {
      title: "Exco Estimate(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.exco_amount)}</div>
      ),
    },
    {
      title: "House Estimate(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.house_amount)}</div>
      ),
    },
    {
      title: "Approved Estimate(₦)",
      custom: true,
      component: (ite, idx) => (
        <div style={{ float: "right" }}>{formatNumber(ite.approve_amount)}</div>
      ),
    },
  ];

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  let query_type = form.transaction_type !== "" ? "budget_type" : "selects";
  // let data = results&&results.map(item => item.name)
  // budget_type;
  const getBudgetNew = useCallback(() => {
    setLoading(true);
    _fetchApi(
      `/get-budget-new/${mda_name}?query_type=${query_type}&budget_year=${form.budget_year}&transaction_type=${form.transaction_type}`,
      (res) => {
        const modifiedResults = res.results
          .map((item) => {
            if (!item.approve_amount) {
              return { ...item, approve_amount: item.house_amount };
            }
            return item;
          })
          .filter(
            (item) =>
              item.status === "final-house" || item.status === "approved"
          );

        setResults(modifiedResults);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [mda_name, form.budget_year, form.transaction_type, query_type]);

  useEffect(() => {
    getBudgetNew();
  }, [getBudgetNew]);

  const totalAmount = results?.reduce(
    (total, item) => parseFloat(item.approve_amount) + total,
    0
  );

  return (
    <Card>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        // ml={30}
        variant="gradient"
        bgColor="success"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h5" color="white" className="text-center">
          {mda_name} Budget Report
        </MDTypography>
      </MDBox>

      <MDBox>
        <MDBox pt={0}>
          <div className=" row p-3">
            <div className="col-md-4 mb-3">
              <label>Budget Type</label>
              <select
                name="transaction_type"
                onChange={handleChange}
                className="form-control"
                value={form.transaction_type}
              >
                <option value="">...Select...</option>
                <option value="Revenue">Revenue</option>
                <option value="Capital">Capital</option>
                <option value="Capital Receipt">Capital Receipt</option>
                <option value="Personnel">Personnel</option>
                <option value="OverHead">OverHead</option>
              </select>
            </div>
            <div className="col-md-4">
              <Label>Select budget year</Label>
              <Input
                type="select"
                name="budget_year"
                value={form.budget_year}
                onChange={handleChange}
              >
                <option>--select--</option>
                {years?.map((item) => (
                  <option key={item}>{item}</option>
                ))}
              </Input>
            </div>
            <div
              className="col-md-4"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div>
                <Button color="danger" size="sm" onClick={toggle}>
                  X Close
                </Button>
              </div>
            </div>
            <span style={{ fontWeight: "bold", float: "right" }}>
              TOTAL : ₦{totalAmount?.toLocaleString()}
            </span>
            {loading && (
              <div className="loading-container text-center">
                <Spinner color="primary" style={{ marginTop: "20px" }} />{" "}
                Loading...
              </div>
            )}
            <Scrollbar>
              <CustomTable fields={fields} data={results} type="approve" />
            </Scrollbar>
          </div>
        </MDBox>
      </MDBox>
    </Card>
  );
}
