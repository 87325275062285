const allModule = {
  account: {
    name: "Chart Of Account",
    type: [
      "Dashboard",
      "Account Chart",
      "Administrative Segment",
      "Economic Segment",
      "Functional Segment",
      "Programme Segment",
      "Fund Segment",
      "Geo Segment",
      "Account Chart Setup"
    ],
  },

  budget: {
    name: "Main Org ceiling",
    type: ["Sector Budget Ceilling", "MDA Budget Ceilling"],
  },
  release: {
    name: "Release Processing",
    type: [
      "Release Processing",
      "Internal Control",
      "Approval Review",
      "Monthly Recurrent Setup",
      "Release Cancellation",
      "Unclear Release",
    ],
  },
  mda_mapping: {
    name: "MDA Mapping",
    type: [
      "MDA Mapping",
      "Economic Segment Mapping",
      // "Administrative Segment Mapping",
      "Functional Segment Mapping",
      "Programme Segment Mapping",
      "Fund Segment Mapping",
      "Geo Segment Mapping",
    ],
  },

  approval_collection: {
    name: "Release Processing",
    type: [
      "Release Processing",
      "Internal Control",
      "Approval Review",
      "Monthly Recurrent Setup",
      "Release Cancellation",
      "Unclear Release",
    ],
  },
  virment: {
    name: "Virement",
    type: ["Planning Reserve"],
  },

  ccopies: {
    name: "CC Copies",
    type: ["CC_Copies"],
  },
  budget_type: {
    name: "Budget Procceses",
    type: [
      "Proposed Budget",
      "MOPB Recomendation",
      "Exco Recomedation",
      "House Recommendation",
      "Approve Recommendation",
      // "Supplementary",
      "Budget Breakdown",
      "Revised Estimate",
    ],
  },
  reports: {
    name: "Reports",
    type: [
      "Approved Budget",
      "Budget Report Tracker",
      "Publication Reports",
      "Appropriation Law",
      "Budget Overview",
      "Release Report",
      "Revenue Reports",
      // "Total Revenue by Admin",
      // "Expenditure by Department",
      // "Expenditure by MDA",
      "Revenue Reports by Admin",
      "Total Revenue by Economic",
      "Capital Receipts by item",
      "Total Revenue by Fund",
      "Expenditure Reports",
      "Expenditure by Admin",
      "Exp by Programme",
      "Exp by Location",
      "Exp by Fund",
      "Exp by Economic",
      "Exp by Function",
      "Exp by Project",
      // "Oil Rec Revenue by Admin",
      // "Oil Capital Rec Revenue by Admin",
      // "Total Revenue by Economic",
      // "Receipt Revenue by Fund",
      // "Capital Receipts by Fund",
      // "Total Expenditure by Fund",
      // "Personnel Expenditure by Fund",
      // "Overhead Expenditure by Fund",
    ],
  },
  m_and_e: {
    name: "M And E",
    type: [
      "Agriculture Sector",
      "Commerce Sector",
      "Education Sector",
      "Nature Resource Sector",
      "Health Sector",
      "Housing and Transport Sector",
      // "Goods Transfer Form",
      "State M&E",
      // "Sample Monitoring",
    ],
  },
  setup: {
    name: "Admin",
    type: ["User Management", "Other Setup", "Upload Budget"],
  },
};

export default allModule;
