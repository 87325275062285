/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================


 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout, setToggleNav } from "context";
import "./dashboardnav.css";
import logo from "../../../assets/images/k-removebg-preview.png";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, toggleNav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);
  const handleClick = () => {
    setToggleNav(dispatch, !toggleNav);
  };
  return (
    <>
      <div className="container-fluid mx-auto nav-sm bg-success">
        <div className="toggle-container">
          <Link to="" className="nav-toggle-img">
            <img src={logo} />
          </Link>
          <h3 className="text-center text-white">
            KATSINA STATE E-BUDGET (KTEB)
          </h3>
          <button
            className="toggle-button btn btn-success"
            onClick={handleClick}
          >
            <span className="">
              {toggleNav ? <FaBars /> : <MdOutlineClose />}
            </span>
          </button>
        </div>
      </div>
      <MDBox
        // bgColor="success"
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",

          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </MDBox>
    </>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
