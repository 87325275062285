import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Card,
  Row,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import CustomBarChart from "./NewCharts/CustomBarChart";
import PieChartComponent from "./NewCharts/CustomPieChart";
import { data2, data3, data4, data5 } from "./data";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
import { _fetchApi } from "redux/action/api";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
// import Arrow from "./Arrow";
import { formatNumber } from "redux/action/api";
import { PDFViewer } from "@react-pdf/renderer";
import PerformancePdf from "components/Tree/BudgetPerformancePdf";
import { unflattenTable } from "redux/action/api";
import "./arrow.css";
import { getNextYear } from "layouts/tables/getBudgetYear";

export const getAllData = (query_type, date_from, date_to, cb) => {
  _fetchApi(
    `/dashboard-budget?query_type=${query_type}&from=${date_from}&to=${date_to}`,
    (data) => {
      console.log("XCXCXXXXXXXXXXXXX", data);
      cb(data.results);
    },
    (err) => {
      console.log(err);
    }
  );
};

const getCurrentQuarter = () => {
  const month = new Date().getMonth() + 1;
  if (month >= 1 && month <= 3) return "Q1";
  if (month >= 4 && month <= 6) return "Q2";
  if (month >= 7 && month <= 9) return "Q3";
  if (month >= 10 && month <= 12) return "Q4";
};
const getQuarterDateRange = (quarter) => {
  const year = new Date().getFullYear();
  switch (quarter) {
    case "Q1":
      return { from: `${year}-01-01`, to: `${year}-03-31` };
    case "Q2":
      return { from: `${year}-04-01`, to: `${year}-06-30` };
    case "Q3":
      return { from: `${year}-07-01`, to: `${year}-09-30` };
    case "Q4":
      return { from: `${year}-10-01`, to: `${year}-12-31` };
    default:
      return { from: "", to: "" };
  }
};

function NewDashboard() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState(null);
  const moments = moment().format("YYYY-MM-DD");
  const yearAgo = moment().subtract(1, "year").format("YYYY-MM-DD");
  const [selectQuarter, setSelectQuarter] = useState({
    quarter: getCurrentQuarter(),
    from: yearAgo,
    to: moments,
  });
  const [form, setForm] = useState({ budget_year: "" });
  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);

  const { sales, tasks } = reportsLineChartData;
  const [mda, setMda] = useState([]);
  const [budgeted, setBudgeted] = useState([]);
  const [expense, setExpense] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [budgetActual, setBudgetActual] = useState([]);
  const [revenueByEconomic, setrevenueByEconomic] = useState([]);
  const [expByEconomic, setExpByEconomic] = useState([]);
  const [mainSector, setMainSector] = useState([]);
  const [expByFunc, setExpByFunc] = useState([]);

  useEffect(() => {
    getAllData("mda", selectQuarter.from, selectQuarter.to, setMda);
    getAllData(
      "budget_amount",
      selectQuarter.from,
      selectQuarter.to,
      setBudgeted
    );
    getAllData("expenses", selectQuarter.from, selectQuarter.to, setExpense);
    getAllData(
      "exp_by_func",
      selectQuarter.from,
      selectQuarter.to,
      setExpByFunc
    );
    getAllData("revenue", selectQuarter.from, selectQuarter.to, setRevenue);
    getAllData(
      "revenue_economic",
      selectQuarter.from,
      selectQuarter.to,
      setrevenueByEconomic
    );
    getAllData(
      "exp_economic",
      selectQuarter.from,
      selectQuarter.to,
      setExpByEconomic
    );
    getAllData(
      "exp_main_sector",
      selectQuarter.from,
      selectQuarter.to,
      setMainSector
    );
    getAllData(
      "budget-actual",
      selectQuarter.from,
      selectQuarter.to,
      (resp) => {
        console.log(resp, "ddddddddddd");
        const finalData = [
          {
            name: "TOTAL REVENUE",
            "Total Budget": resp[0]["Total Revenue Budget"],
            "Total Actual": resp[0]["Total Revenue Actual"],
            Performance: (
              parseFloat(
                parseFloat(resp[0]["Total Revenue Actual"] || 0) /
                  parseFloat(resp[0]["Total Revenue Budget"])
              ) * 100
            ).toFixed(2),
            // amt: 120,
          },
          {
            name: "TOTAL EXPENDITURE",
            "Total Budget": resp[0]["Total Expenditure Budget"],
            "Total Actual": resp[0]["Total Expenditure Actual"],
            Performance: (
              parseFloat(
                parseFloat(resp[0]["Total Expenditure Actual"] || 0) /
                  parseFloat(resp[0]["Total Expenditure Budget"])
              ) * 100
            ).toFixed(2),
            // amt: 120,
          },
        ];
        setBudgetActual(finalData);
      }
    );
  }, [selectQuarter.from, selectQuarter.to]);

  const toggleModal = (chart) => {
    setSelectedChart(chart);
    setModalOpen(!modalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectQuarter((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "quarter" && value !== "custom") {
      const dateRange = getQuarterDateRange(value);
      setSelectQuarter((prevState) => ({
        ...prevState,
        from: dateRange.from,
        to: dateRange.to,
      }));

      console.log("Date Range:", dateRange);
    }
  };
  const handleCustomDateChange = (e) => {
    const { name, value } = e.target;
    setSelectQuarter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getCustomDate = (quarter) => {
    if (quarter === "custom") {
      return (
        <div className="d-flex">
          <div>
            <label>From</label> <br />
            <Input
              type="date"
              id="from"
              name="from"
              className="w-100"
              value={selectQuarter.from}
              onChange={handleCustomDateChange}
            />
          </div>
          <div>
            <label>To</label> <br />
            <Input
              type="date"
              id="to"
              name="to"
              className="w-100"
              value={selectQuarter.to}
              onChange={handleCustomDateChange}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  // let expByEconomic = sumBudgetData(expByEconomic);
  // let mainSector = sumMainData(mainSector);

  const [generate, setGenerate] = useState(false);
  const toggle = () => {
    setGenerate(!generate);
  };
  const [treeData, setTreeData] = useState([]);
  const [treeData1, setTreeData1] = useState([]);
  const [treeData2, setTreeData2] = useState([]);
  const [treeData3, setTreeData3] = useState([]);
  const [treeData4, setTreeData4] = useState([]);
  const [treeData5, setTreeData5] = useState([]);
  const [treeData6, setTreeData6] = useState([]);
  const [treeData7, setTreeData7] = useState([]);
  const [treeData8, setTreeData8] = useState([]);
  const [treeData9, setTreeData9] = useState([]);
  const [treeData10, setTreeData10] = useState([]);
  const [treeData11, setTreeData11] = useState([]);

  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=total_ex_by_function&budget_year=${form.budget_year}&like=null`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=ex_by_function&budget_year=${form.budget_year}&like=21%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData1(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=ex_by_function&budget_year=${form.budget_year}&like=22%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData2(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=ex_by_function&budget_year=${form.budget_year}&like=32%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData3(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=ex_by_program&budget_year=${form.budget_year}&like=21%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData4(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=total_revenue_by_admin&budget_year=${form.budget_year}&like=21%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData5(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=expenditure_by_department&budget_year=${form.budget_year}&like=21%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData6(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=ex_by_admin&budget_year=${form.budget_year}&like=21%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData7(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=ex_by_admin&budget_year=${form.budget_year}&like=32%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData8(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=ex_by_admin&budget_year=${form.budget_year}&like=22%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData9(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    // setLoading(true);
    _fetchApi(
      `/old/reports?query_type=total_revenue_by_economic&budget_year=${form.budget_year}&like=22%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData10(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/old/reports?query_type=other_exp_func&budget_year=${form.budget_year}&like=22%`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData11(tt);

        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, []);

  return (
    <Row className="m-0 p-0">
      {/* {JSON.stringify(form.budget_year)} */}
      <Modal
        size="lg"
        className="p-3 rounded"
        isOpen={modalOpen}
        toggle={toggleModal}
        centered
        style={{
          marginLeft: 300,
          fontSize: 12,
        }}
      >
        <ModalHeader toggle={toggleModal}>
          {selectedChart && selectedChart.title}
        </ModalHeader>
        <ModalBody>{selectedChart && selectedChart.chart}</ModalBody>
      </Modal>
      <Row>
        <Col md={12}>
          <div className="d-flex justify-content-between mb-3">
            <div className="col-md-4">
              <label>Select Quarter</label>
              <Input
                type="select"
                name="quarter"
                value={selectQuarter.quarter}
                onChange={handleChange}
              >
                <option value="Q1">Q1</option>
                <option value="Q2">Q2</option>
                <option value="Q3">Q3</option>
                <option value="Q4">Q4</option>
                <option value="custom">Custom</option>
              </Input>
            </div>
            <div className="col-md-6 ">
              {getCustomDate(selectQuarter.quarter)}
            </div>
            {/* <div className="col-md-3">
              <Button color="primary" onClick={toggle}>
                {generate
                  ? "Close"
                  : `Generate ${selectQuarter.quarter} Report`}
              </Button>
            </div> */}
          </div>
        </Col>
      </Row>
      {generate ? (
        <>
          {loading && (
            <div className="loading-container text-center">
              <Spinner color="primary" style={{ marginTop: "20px" }} />{" "}
              Loading...
            </div>
          )}
          <PDFViewer width={1500} height={2000}>
            <PerformancePdf
              data={treeData || []}
              data2={treeData1 || []}
              data3={treeData2 || []}
              data4={treeData3 || []}
              data5={treeData4 || []}
              data6={treeData5 || []}
              data7={treeData6 || []}
              data8={treeData7 || []}
              data9={treeData8 || []}
              data10={treeData9 || []}
              data11={treeData10 || []}
              data12={treeData11 || []}
            />
          </PDFViewer>
        </>
      ) : (
        <>
          <Col md={7}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      AGGREGATE PERFORMANCE
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: `AGGREGATE PERFORMANCE`,
                          chart: (
                            <CustomBarChart
                              data={budgetActual}
                              width={780}
                              height={400}
                              uvDataKey="Total Budget"
                              pvDataKey="Total Actual"
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <CustomBarChart
                    data={budgetActual}
                    width={800}
                    height={400}
                    uvDataKey="Total Budget"
                    pvDataKey="Total Actual"
                  />
                </Row>
              </div>
            </Card>
          </Col>
          <Col
            md={5}
            className="card mt-2"
            style={{
              height: 480,
              fontSize: 15,
              fontWeight: "bold",
            }}
          >
            {/* <Arrow /> */}
            <h4 className="text-center mt-2">
              {selectQuarter.quarter} Flow of Funds
            </h4>
            <div className="floew up">
              <div className="fundss">
                <div className="lebel lebel-right">Incoming (Revenue)</div>
                <div className="lebel lebel-right">
                  {formatNumber(revenue[0]?.total || 0)}
                </div>
              </div>
              <div className="head-right"></div>
            </div>
            <div className="floew">
              <div className="head-left"></div>
              <div className="fundss">
                <div className="lebel lebel-left">Outgoing (Expenditure)</div>
                <div className="lebel lebel-left">
                  {formatNumber(expense[0]?.total || 0)}
                </div>
              </div>
            </div>
            {/* <div
              className="d-flex justify-content-betwee text-light"
              style={{ position: "absolute", top: "30%" }}
            >
              <p style={{ marginRight: 80, marginLeft: 20 }}>
                Incoming (Revenue)
              </p>
              <p>{formatNumber(revenue[0]?.total || 0)}</p>
            </div>
            <img
              src={require("../../assets/images/image.png")}
              alt="arror-leftright"
              className="img-fluid mt-5"
            />
            <div
              className="d-flex justify-content-betwee text-light"
              style={{ position: "absolute", top: "55%" }}
            >
              <p style={{ marginRight: 50, marginLeft: 28 }}>
                Outgoing (Expenditure)
              </p>
              <p>{formatNumber(expense[0]?.total || 0)}</p>
            </div> */}
          </Col>

          <Col md={7}>
            <Card
              body
              className="my-2"
              // style={{
              //   height: "24em",
              // }}
            >
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      REVENUE PERFORMANCE BY ECONOMIC
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: `REVENUE PERFORMANCE BY ECONOMIC`,
                          chart: (
                            <CustomBarChart
                              data={data5(revenueByEconomic)}
                              width={780}
                              height={400}
                              uvDataKey="Quarterly Budget"
                              pvDataKey="Actual for Quarter"
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <CustomBarChart
                    data={data5(revenueByEconomic)}
                    width={780}
                    height={400}
                    uvDataKey="Quarterly Budget"
                    pvDataKey="Actual for Quarter"
                  />
                </Row>
              </div>
            </Card>
          </Col>
          <Col md={5}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      REVENUE COMPOSITION BY ECONOMIC
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: `REVENUE PERFORMANCE BY ECONOMIC`,
                          chart: (
                            <PieChartComponent
                              data={data5(revenueByEconomic, "pie")}
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <PieChartComponent data={data5(revenueByEconomic, "pie")} />
                </Row>
              </div>
            </Card>
          </Col>

          <Col md={7}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "80%",
                      }}
                    >
                      EXPENDITURE PERFORMANCE BY ECONOMIC
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: `EXPENDITURE PERFORMANCE BY ECONOMIC`,
                          chart: (
                            <CustomBarChart
                              data={data2(expByEconomic)}
                              width={780}
                              height={400}
                              uvDataKey="Quarterly Budget"
                              pvDataKey="Actual for Quarter"
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <CustomBarChart
                    data={data2(expByEconomic)}
                    width={800}
                    height={400}
                    uvDataKey="Quarterly Budget"
                    pvDataKey="Actual for Quarter"
                  />
                </Row>
              </div>
            </Card>
          </Col>
          <Col md={5}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      EXPENDITURE PERFORMANCE BY ECONOMIC
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: `EXPENDITURE PERFORMANCE BY ECONOMIC`,
                          chart: (
                            <PieChartComponent
                              data={data2(expByEconomic, "pie")}
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <PieChartComponent data={data2(expByEconomic, "pie")} />
                </Row>
              </div>
            </Card>
          </Col>

          <Col md={7}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      EXPENDITURE PERFORMANCE BY MAIN SECTOR
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: `EXPENDITURE PERFORMANCE BY MAIN SECTOR`,
                          chart: (
                            <CustomBarChart
                              data={data3(mainSector)}
                              width={780}
                              height={400}
                              uvDataKey="Quarterly Budget"
                              pvDataKey="Actual for Quarter"
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <CustomBarChart
                    data={data3(mainSector)}
                    width={800}
                    height={400}
                    uvDataKey="Quarterly Budget"
                    pvDataKey="Actual for Quarter"
                  />
                </Row>
              </div>
            </Card>
          </Col>
          <Col md={5}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      EXPENDITURE COMPOSITION BY MAIN SECTOR
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: `EXPENDITURE PERFORMANCE BY MAIN SECTOR`,
                          chart: (
                            <PieChartComponent
                              data={data3(mainSector, "pie")}
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <PieChartComponent data={data3(mainSector, "pie")} />
                </Row>
              </div>
            </Card>
          </Col>

          <Col md={7}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "80%",
                      }}
                    >
                      EXPENDITURE PERFORMANCE BY FUNCTION
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: `EXPENDITURE PERFORMANCE BY FUNCTION`,
                          chart: (
                            <CustomBarChart
                              data={data4(expByFunc)}
                              width={780}
                              height={400}
                              uvDataKey="Quarterly Budget"
                              pvDataKey="Actual for Quarter"
                            />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <CustomBarChart
                    data={data4(expByFunc)}
                    width={800}
                    height={400}
                    uvDataKey="Quarterly Budget"
                    pvDataKey="Actual for Quarter"
                  />
                </Row>
              </div>
            </Card>
          </Col>
          <Col md={5}>
            <Card body className="my-2">
              <div
                style={{
                  fontSize: 12,
                }}
              >
                <Row className="m-0 p-0">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h6
                      className="text-center fw-bold"
                      style={{
                        width: "90%",
                      }}
                    >
                      EXPENDITURE PERFORMANCE BY FUNCTION
                    </h6>
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 12,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={() =>
                        toggleModal({
                          title: `EXPENDITURE PERFORMANCE BY FUNCTION`,
                          chart: (
                            <PieChartComponent data={data4(expByFunc, "pie")} />
                          ),
                        })
                      }
                    >
                      expand
                    </span>
                  </div>
                  <PieChartComponent data={data4(expByFunc, "pie")} />
                </Row>
              </div>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
}

export default NewDashboard;
