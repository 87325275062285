/* eslint-disable react/jsx-props-no-spreading */
// src/components/ExcelUpload.js
import React, { useState, useMemo, useEffect } from "react";
import * as XLSX from "xlsx";
// import axios from "axios";
import { saveAs } from "file-saver";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { Button, Card, Col, Row, Table } from "reactstrap";
import { _postApi } from "redux/action/api";
import { useToasts } from "react-toast-notifications";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import Scrollbar from "components/Tree/Scrollbar";
import { FaFileExcel } from "react-icons/fa";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const ExcelUpload = () => {
  const [data, setData] = useState([]);

  const handleFileUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    // reader.onload = (event) => {
    //   const binaryStr = event.target.result;
    //   const workbook = XLSX.read(binaryStr, { type: "binary" });
    //   const sheetName = workbook.SheetNames[0];
    //   const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
    //     header: 1,
    //   });

    //   // Extract headers and rows
    //   const headers = sheet[0];
    //   const rows = sheet.slice(1);

    //   // Convert rows to array of objects
    //   const formattedData = rows.map((row) => {
    //     const rowData = {};
    //     headers.forEach((header, index) => {
    //       rowData[header] = row[index];
    //     });
    //     return rowData;
    //   });

    //   setData(formattedData);
    // };

    reader.readAsBinaryString(file);
  };

  const { addToast } = useToasts();
  const handleSubmit = async () => {
    const apiEndpoint = `/post-budget?query_type=INSERT&status=draft`;
    _postApi(
      apiEndpoint,
      data,
      (res) => {
        if (res.success) {
          addToast("Submitted Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          navigate(-1);
        }
      },
      (err) => {
        console.log(err);
        alert("An error occurred!");
      }
    );
  };

  // const generateTemplate = () => {
  //   const headings = [
  //     "budget_year",
  //     "admin_code",
  //     "admin_description",
  //     "economic_code",
  //     "economic_description",
  //     "program_code",
  //     "program_description",
  //     "function_code",
  //     "function_description",
  //     "fund_code",
  //     "fund_description",
  //     "geo_code",
  //     "geo_description",
  //     "line_item",
  //     "budget_amount",
  //     "actual_amount",
  //     "budget_type",
  //     "start_date",
  //     "end_date",
  //     "status",
  //     "transaction_type",
  //     "segment",
  //     "mopb_amount",
  //     "exco_amount",
  //     "house_amount",
  //     "approve_amount",
  //     "supplementary_amount",
  //     "revised_amount",
  //     "mda_name",
  //     "mda_code",
  //     "id",
  //     "type_of_budget",
  //     "description",
  //   ];

  //   const worksheet = XLSX.utils.aoa_to_sheet([headings]);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  //   saveAs(blob, "template.xlsx");
  // };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ onDrop: handleFileUpload, accept: ".xlsx,.xls" });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  Upload Budget
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={3}>
                <div>
                  <Row>
                    <Col md={4}>
                      {/* <Button
                        color="primary"
                        className="mr-5"
                        onClick={generateTemplate}
                      >
                        <FaFileExcel /> Download Excel Template{" "}
                      </Button> */}
                    </Col>
                    <Col md={6}></Col>

                    <Col md={2}>
                      {data.length > 0 && (
                        <Button
                          color="primary"
                          // className="pl-5 pr-5 p-3"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {/* {JSON.stringify(data)} */}
                  <div className="mv-2">
                    <h3 style={{ color: "#59B15D" }}>Upload Attachment</h3>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag drop some files here, or click to select files</p>
                    </div>
                    {files.length ? (
                      <aside>
                        <h4>Files</h4>
                        <ul>{files}</ul>
                      </aside>
                    ) : null}
                  </div>
                  {/* {data.slice(1).map((row, rowIndex) => ({ row }))} */}
                  {/* {JSON.stringify(transformedData)} */}
                  <Scrollbar>
                    {data.length > 0 && (
                      <Table bordered striped>
                        <thead>
                          <tr>
                            {Object.keys(data[0]).map((key) => (
                              <th key={key}>{key}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {Object.values(row).map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </Scrollbar>
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

ExcelUpload.propTypes = {
  onDrop: PropTypes.func,
};

export default ExcelUpload;
