/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useState } from "react";
import Scrollbar from "components/Tree/Scrollbar";

import moment from "moment";
// import SearchBar from "components/SearchBar";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import { capitalizeWords } from "redux/action/api";
import { unflattenTable, unflatten } from "redux/action/api";
import {
  ButtonGroup,
  Button,
  Input,
  Modal,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import MDAReportModal from "./MDAReportsModal";
import { headerColors } from "redux/action/api";
import { getNextYear } from "../getBudgetYear";

export default function PublicationReports({
  type = "revenue_by_admin",
  route = "/publication-reports",
  options = [],
  title = "",
}) {
  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle1 = () => setDropdownOpen((prevState) => !prevState);

  const [treeData, setTreeData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: type,
  });

   useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const [selectedOption, setSelectedOption] = useState({
    value: type,
    title,
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  const likeVar = selectedOption?.title.includes("Personnel")
    ? "21%"
    : selectedOption?.title.includes("Debt")
    ? "2206%"
    : selectedOption?.title.includes("Capital")
    ? "32%"
    : selectedOption?.title.includes("Other")
    ? "22%"
    : null;

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData(tt);

        const formattedResults = {};

        // console.log(data?.results);
        data?.results.forEach((item) => {
          let resultExists = Object.keys(formattedResults).includes(
            item.mda_code
          );
          if (item.mda_code) {
            if (resultExists) {
              formattedResults[item.mda_code] = [
                ...formattedResults[item.mda_code],
                item,
              ];
            } else {
              formattedResults[item.mda_code] = [item];
            }
          }
        });

        const nn = {};
        Object.keys(formattedResults).forEach((key) => {
          nn[key] = unflattenTable(formattedResults[key]);
        });

        setFormattedData(nn);

        setLoading(false);
        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [selectedOption.value]);

  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];

  const calculateprevyear = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year2_actual_amount;
    } else {
      let sum = node.prev_year2_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateprevyear(child);
      }
      return sum;
    }
  };

  const calculateApprovePrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_approve_amount;
    } else {
      let sum = node.prev_year1_approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApprovePrev1(child);
      }
      return sum;
    }
  };
  const calculateActualPrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_actual_amount;
    } else {
      let sum = node.prev_year1_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActualPrev1(child);
      }
      return sum;
    }
  };

  const calculateBudgetAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.budget_amount;
    } else {
      let sum = node.budget_amount || 0;
      for (const child of node.children) {
        sum += calculateBudgetAmt(child);
      }
      return sum;
    }
  };

  const calculateApproveAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.approve_amount;
    } else {
      let sum = node.approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApproveAmt(child);
      }
      return sum;
    }
  };

  const generateTable3 = (tree) => {
    const renderItems = (item, index) => {
      const isTitle =
        item.code === "0000000000000000000" ||
        item.code === "1000000000000000000" ||
        item.code === "2000000000000000000" ||
        item.code === "3000000000000000000" ||
        item.code === "5000000000000000000" ||
        item.code === "7000000000000000000" ||
        item.code === "8000000000000000000";
      return (
        <React.Fragment key={`group_${index}`}>
          <tr
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "black",
              cursor: "pointer",
            }}
            onClick={() => {
              toggle();
              setSelectMDA(item);
              console.log(item);
            }}
          >
            {/* {isTitle ? "" : item.code} */}
            <td
              style={{
                backgroundColor: headerColors[item.parentHeads.length],
                color: "black",
              }}
            >
              {isTitle ? "" : item.code.replace(/0+$/, "")}
            </td>
            <td
              style={{
                backgroundColor: headerColors[item.parentHeads.length],
                color: "black",
              }}
            >
              {isTitle ? selectedOption.title : item.description}
              {/* {form.type} */}
            </td>
            <td
              style={{
                textAlign: "right",
                backgroundColor: headerColors[item.parentHeads.length],
                color: "black",
              }}
            >
              {isNaN(parseFloat(calculateprevyear(item)))
                ? "---"
                : parseFloat(calculateprevyear(item)).toLocaleString()}
            </td>
            <td
              style={{
                textAlign: "right",
                backgroundColor: headerColors[item.parentHeads.length],
                color: "black",
              }}
            >
              {isNaN(parseFloat(calculateApprovePrev1(item)))
                ? "---"
                : parseFloat(calculateApprovePrev1(item)).toLocaleString()}
            </td>
            <td
              style={{
                textAlign: "right",
                backgroundColor: headerColors[item.parentHeads.length],
                color: "black",
              }}
            >
              {isNaN(parseFloat(calculateActualPrev1(item)))
                ? "---"
                : parseFloat(calculateActualPrev1(item)).toLocaleString()}
            </td>
            <td
              style={{
                textAlign: "right",
                backgroundColor: headerColors[item.parentHeads.length],
                color: "black",
              }}
            >
              {isNaN(parseFloat(calculateBudgetAmt(item)))
                ? "---"
                : parseFloat(calculateBudgetAmt(item)).toLocaleString()}
            </td>
            <td
              style={{
                textAlign: "right",
                backgroundColor: headerColors[item.parentHeads.length],
                color: "black",
              }}
            >
              {isNaN(parseFloat(calculateApproveAmt(item)))
                ? "---"
                : parseFloat(calculateApproveAmt(item)).toLocaleString()}
            </td>
          </tr>

          {item.children.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else {
              return (
                <tr key={child.code} style={{ fontSize: "11px" }}>
                  <td>{child.code}</td>
                  <td>{child.description}</td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.prev_year2_actual_amount))
                      ? "---"
                      : parseFloat(
                          child.prev_year2_actual_amount
                        ).toLocaleString()}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.prev_year1_approve_amount))
                      ? "---"
                      : parseFloat(
                          child.prev_year1_approve_amount
                        ).toLocaleString()}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.prev_year1_actual_amount))
                      ? "---"
                      : parseFloat(
                          child.prev_year1_actual_amount
                        ).toLocaleString()}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.budget_amount))
                      ? "---"
                      : parseFloat(child.budget_amount).toLocaleString()}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.approve_amount))
                      ? "---"
                      : parseFloat(child.approve_amount).toLocaleString()}
                  </td>
                </tr>
              );
            }
          })}
        </React.Fragment>
      );
    };

    return (
      // <table className="table table-bordered">
      // <thead>
      <>
        <tr
          style={{
            fontSize: "12px",
            borderWidth: 1,
            borderColor: "black",
            textAlign: "center",
            backgroundColor: "orange",
          }}
        >
          <th style={{ backgroundColor: "orange" }}>Code</th>
          <th style={{ backgroundColor: "orange" }}> Description</th>
          <th style={{ backgroundColor: "orange" }}>{`${
            parseInt(form.budget_year) - 2
          }  Actual(₦)`}</th>
          <th style={{ backgroundColor: "orange" }}>{`${
            parseInt(form.budget_year) - 1
          } Aproved(₦)`}</th>
          <th style={{ backgroundColor: "orange" }}>{`${
            parseInt(form.budget_year) - 1
          } Actual(₦)`}</th>
          <th style={{ backgroundColor: "orange" }}>{`${parseInt(
            form.budget_year
          )} MDA Proposal`}</th>
          <th style={{ backgroundColor: "orange" }}>{`${parseInt(
            form.budget_year
          )} Approved Budget`}</th>
        </tr>
        {/* // </thead> */}
        {/* // <tbody> */}
        {tree.map((item, i) => renderItems(item, i))}
      </>
      // {/* </tbody> */}
      // {/* {JSON.stringify(treeData.length)} */}
      // {/* </table> */}
    );
  };

  return (
    <>
      <DashboardLayout>
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div>
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  size="lg"
                  style={{
                    marginLeft: "30%",
                  }}
                >
                  <MDAReportModal
                    toggle={toggle}
                    mda_name={selectMDA.description}
                  />
                </Modal>
              </div>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h5" color="white">
                    {capitalizeWords(selectedOption.title)}
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  {/* {JSON.stringify({ formattedData })}xxxx */}
                  <div
                    className="d-flex flex-wrap justify-content-between m-1"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  >
                    <div className="col-md-2 col-12 mb-1">
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                    <div className="col-md-8 mb-1 mx-auto">
                      <div className="text-center">
                        {/* <label>Select the report type</label> */}
                      </div>
                      <center>
                        <ButtonGroup>
                          {options.map((item) => (
                            <Button
                            style={{lineHeight: "12px", width: "10ch" }}
                              size="sm"
                              key={item.title}
                              outline={
                                selectedOption.title !== item.title
                                  ? true
                                  : false
                              }
                              color={
                                selectedOption.title === item.title
                                  ? "primary"
                                  : "secondary"
                              }
                              onClick={() =>
                                handleButtonClick(item.value, item.title)
                              }
                            >
                              <small>{item.title}</small>
                            </Button>
                          ))}
                        </ButtonGroup>
                      </center>
                    </div>
                    <div className="mx-auto">
                      {/* <Button
                        style={{ color: "white" }}
                        className="text-white mb-3 ml-3"
                        size="sm"
                        color="primary"
                      >
                        <CSVLink
                          data={treeData && treeData ? treeData : []}
                          className="text-white"
                          filename={capitalizeWords(selectedOption.title)}
                          headers={headers}
                        >
                          <FaFileDownload color="white" size="1.2rem" />{" "}
                          Download Excelf
                        </CSVLink>
                      </Button> */}
                      <Dropdown isOpen={dropdownOpen} toggle={toggle1}>
                        <DropdownToggle
                          className="btn btn-primary text-white mb-3 ml-3 mx-auto"
                          caret
                        >
                          <FaFileDownload
                            color="white"
                            size="1.2rem"
                            className="mr-5"
                          />
                          Download
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => console.log("Option 1 clicked")}
                          >
                            Excel
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => console.log("Option 2 clicked")}
                          >
                            PDF
                          </DropdownItem>
                          <DropdownItem />
                          <DropdownItem
                            onClick={() =>
                              console.log("Another option clicked")
                            }
                          >
                            Word
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="p-3">
                    {/* {JSON.stringify(treeData)} */}
                    <Scrollbar>
                      {loading && (
                        <div className="loading-container text-center">
                          <Spinner
                            color="primary"
                            style={{ marginTop: "20px" }}
                          />{" "}
                          Loading...
                        </div>
                      )}
                      {/* {generateTable2()} */}

                      {Object.keys(formattedData).map((mdaTitle, index) => (
                        <table className="table table-bordered" key={index}>
                          <tr className="bg-success">
                            <th style={{ fontSize: 12 }}>{mdaTitle}</th>
                            <th style={{ fontSize: 12 }}>
                              {formattedData[mdaTitle][0].mda_name}
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                          {generateTable3(formattedData[mdaTitle])}
                        </table>
                      ))}

                      {/* <Table className="text-black" bordered key={index}>

                            <tr>
                              <th>{mdaTitle}</th>
                              <th>{formattedData[mdaTitle][0].mda_name}</th>
                            </tr>
                            <tr>
                              <th>Code</th>
                              <th>Description</th>
                              <th>Amount</th>
                            </tr>

                            {formattedData[mdaTitle].map((item, idx) => (
                              <tr key={idx}>
                                <td>{item.head}</td>
                                <td>{item.description}</td>
                                <td>{item.prev_year2_actual_amount}</td>
                              </tr>
                            ))}

                      </Table> */}
                    </Scrollbar>
                  </div>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
