// src/layouts/tables/ChartOFAcctSetup.js
import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CustomTypeahead from "components/CustomTypeahead";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { getSector } from "helper";
import { Button, Input, Label, Table } from "reactstrap";
import { year } from "helper";
import { _postApi } from "redux/action/api";
import MDTypography from "components/MDTypography";
import Tables from "layouts/tables";
import { getMDaType } from "helper";

const ChartOFAcctSetup = () => {
  const [formType, setFormType] = useState("split");
  const [mda, setMda] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [data, setdata] = useState([{ name: "", code: "" }]);
  const [levels, setLevel] = useState([]);
  const [subHead, setSubHead] = useState([]);
  const [subHead1, setSubHead1] = useState([]);

  const _form = {
    type: "",
    sub_head: "",
    head: "",
    admin_dec: "",
    economic_code: "",
    economic_name: "",
    year: "",
    merge1: "",
    merge2: "",
    status: "new",
  };
  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(() => {
    getMDaType({ type: form.type }, form.segment, setLevel, "level");
  }, [form.segment, form.type]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getSector({ type: "mdas" }, "all", (d) => {
      setMda(d);
    });
  }, []);

  useEffect(() => {
    getSector({ sub_head: form.head }, "select_subhead", (d) => {
      let newData = d?.map((item) => ({
        ...item,
        status: "new",
      }));
      setSubHead(newData);
    });
  }, [form.head]);

  useEffect(() => {
    getSector({ sub_head: form.merge2 }, "select_subhead", (d) => {
      let newData = d?.map((item) => ({
        ...item,
        status: "new",
      }));
      setSubHead1(newData);
    });
  }, [form.merge2]);

  useEffect(() => {
    getSector({ type: "organization" }, "organization", (d) => {
      setOrganization(d);
    });
  }, []);

  const handleFormTypeChange = (e) => {
    setFormType(e.target.value);
  };

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const addMoreMda = () => {
    setdata([...data, { name: "", code: "" }]);
  };
  const handleMdaChange = (index, field, value) => {
    const newMdas = data.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setdata(newMdas);
  };

  const handleTableChange = (field, value, index) => {
    const _data = subHead.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setSubHead(_data);
  };

  const handleTableChange1 = (field, value, index) => {
    const _data = subHead1.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setSubHead1(_data);
  };

  const handleTableRemove = (it) => {
    let newArr = [];
    subHead?.forEach((item, i) => {
      if (item.head === it.head) {
        newArr.push({
          ...item,
          status: "delete",
        });
      } else {
        newArr.push(item);
      }
    });
    setSubHead(newArr);
  };

  const handleTableRemove1 = (it) => {
    let newArr = [];
    subHead1?.forEach((item, i) => {
      if (item.head === it.head) {
        newArr.push({
          ...item,
          status: "delete",
        });
      } else {
        newArr.push(item);
      }
    });
    setSubHead1(newArr);
  };

  // const { new_head, sub_head, new_desc, level } = form;
  let newData = subHead.filter((item) => item.status === "new");
  let newData1 = subHead1.filter((item) => item.status === "new");

  const handleAdd = () => {
    const newRow = {
      head: "",
      sub_head: "",
      new_head: "New Codew",
      description: "Enter Description",
      status: "new", // Ensure status is correctly set
    };
    setSubHead((prev) => [...prev, newRow]); // Add the new row to state
  };
  const handleAdd1 = () => {
    const newRow = {
      head: "",
      sub_head: "",
      new_head: "New Codew",
      description: "Enter Description",
      status: "new", // Ensure status is correctly set
    };
    setSubHead1((prev) => [...prev, newRow]); // Add the new row to state
  };
  // let newD = newData.map((item) => ([
  //   ...item,
  //   {
  //     head: new_head,
  //     sub_head: sub_head,
  //     description: new_desc,
  //     level: level + 1,
  //   },
  // ]));

  // let newDel = deleteData.map((it) => [
  //   ...it,
  //   {
  //     head: new_head,
  //     sub_head: sub_head,
  //     description: new_desc,
  //     level: level + 1,
  //   },
  // ]);
  let data2 = data;
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let data = subHead.map((it) => ({
      ...it,
      new_sub_head: form.new_head,
      new_description: it.description,
    }));
    let data1 = subHead1.map((it) => ({
      ...it,
      new_sub_head: form.new_head,
      new_description: it.description,
    }));
    let data3 = subHead.map((it) => ({
      ...it,
      new_description: it.description,
    }));
    _postApi(
      `/post-acc-setup?query_type=${formType}`,
      { data, data1, data2, data3, form },
      (resp) => {
        if (resp.success) {
          alert(`${formType} successfully`);
          setForm(_form);
          setLoading(false);
        } else {
          setLoading(false);
          alert(`Error occur on ${formType}`);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };

  return (
    <DashboardLayout>
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="success"
        borderRadius="lg"
        coloredShadow="info"
      >
        <center>
          <MDTypography variant="h2" color="white">
            <center>Chart of Account Setup</center>
          </MDTypography>
        </center>
      </MDBox>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Card sx={{ padding: 3 }}>
              {/* {JSON.stringify(form)} */}
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <label>Select Type</label>
                  <Input
                    type="select"
                    value={formType}
                    onChange={handleFormTypeChange}
                  >
                    <option value="">Select type</option>
                    <option value="split">Split</option>
                    <option value="merge">Merge</option>
                    <option value="move">Move</option>
                  </Input>
                </FormControl>
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                  {formType === "split" && (
                    <>
                      <Grid item xs={12}>
                        <CustomTypeahead
                          options={mda}
                          onChange={(selected) => {
                            let val = selected[0];
                            setForm({
                              ...form,
                              ...val,
                            });
                          }}
                          labelKey={(item) =>
                            `${item.description}-${item.head} (Level: ${item.level})`
                          }
                          label="Select Department to Move"
                          placeholder="Choose Department"
                        />
                      </Grid>

                      {data.map((mda, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={6}>
                            <label>{`MDA Name ${index + 1}`}</label>
                            <Input
                              name="name"
                              value={mda.name || ""}
                              onChange={(e) =>
                                handleMdaChange(index, "name", e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: 0 }}>
                            <label>{`MDA Code ${index + 1}`}</label>
                            <Input
                              name="code"
                              value={mda.code || ""}
                              onChange={(e) =>
                                handleMdaChange(index, "code", e.target.value)
                              }
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                      <Grid item xs={12}>
                        <Button color="primary" onClick={addMoreMda} size="sm">
                          Add More
                        </Button>
                      </Grid>
                    </>
                  )}
                  {formType === "merge" && (
                    <>
                      <Grid item xs={12}>
                        <CustomTypeahead
                          options={mda}
                          onChange={(selected) => {
                            let data = selected[0];
                            setForm({
                              ...form,
                              merge1: data?.head,
                              ...data,
                            });
                          }}
                          labelKey={(item) =>
                            `${item.description}-${item.head} (Level: ${item.level})`
                          }
                          label="Select Lead MDA to Merge"
                          placeholder="Choose Lead MDA"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTypeahead
                          options={mda}
                          onChange={(selected) =>
                            setForm({
                              ...form,
                              merge2: selected[0]?.head,
                              new_sub_head: selected[0]?.sub_head,
                            })
                          }
                          labelKey={(item) =>
                            `${item.description}-${item.head} (Level: ${item.level})`
                          }
                          label="Select Second MDA to Merge"
                          placeholder="Choose Second MDA"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <label>New Name</label>
                        <Input
                          name="new_desc"
                          value={form.new_desc || form.description}
                          onChange={(e) =>
                            setForm({ ...form, new_desc: e.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label>New Code</label>
                        <Input
                          name="new_head"
                          value={form.new_head || form.head}
                          onChange={(e) =>
                            setForm({ ...form, new_head: e.target.value })
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {formType === "move" && (
                    <>
                      <Grid item xs={12}>
                        <CustomTypeahead
                          options={mda}
                          onChange={(selected) => {
                            let val = selected[0];
                            setForm({
                              ...form,
                              ...val,
                            });
                          }}
                          labelKey={(item) =>
                            `${item.description}-${item.head} (Level: ${item.level})`
                          }
                          label="Select Department to Move"
                          placeholder="Choose Department"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <label>Select Level</label>
                          <Input
                            type="select"
                            value={form.segment}
                            onChange={handleChange}
                            name="segment"
                          >
                            <option value="">Select Level </option>
                            <option value="1"> Level 1</option>
                            <option value="2"> Level 2</option>
                            <option value="3"> Level 3</option>
                            <option value="4"> Level 4</option>
                            <option value="5"> Level 5</option>
                          </Input>
                        </FormControl>
                      </Grid>
                      {form.segment && (
                        <Grid item xs={12}>
                          <CustomTypeahead
                            options={levels}
                            onChange={(selected) =>
                              setForm({ ...form, sub_head: selected[0]?.head })
                            }
                            labelKey={(item) =>
                              `${item.description}-${item.head}  (Level: ${item.level})`
                            }
                            label="Select Destination Organization"
                            placeholder="Choose Destination"
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <label>New Name</label>
                        <Input
                          name="new_desc"
                          value={form.new_desc || form.description}
                          onChange={(e) =>
                            setForm({ ...form, new_desc: e.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label>New Code</label>
                        <Input
                          name="new_head"
                          value={form.new_head || form.head}
                          onChange={(e) =>
                            setForm({ ...form, new_head: e.target.value })
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <center>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: 3 }}
                        disabled={loading}
                      >
                        Submit
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ padding: 3 }}>
              {newData.length ? (
                <Table size="sm">
                  <thead>
                    <tr style={{ fontSize: "15px" }}>
                      <th>
                        {" "}
                        {formType === "split"
                          ? "Enter New Sub-Head"
                          : "Old Code"}
                      </th>
                      <th>
                        {formType === "split"
                          ? "Enter New Head Code"
                          : "Enter New Code"}
                      </th>
                      <th className="d-flex justify-content-between">
                        <div>Description</div>
                        <Button color="success" size="sm" onClick={handleAdd}>
                          Add
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {newData?.map((item, id) => (
                      <tr key={id}>
                        <td>
                          {formType === "split" ? (
                            <Input
                              value={item.new_sub_head || item.head}
                              onChange={({ target: { value } }) => {
                                handleTableChange("new_sub_head", value, id);
                              }}
                              placeholder="New Sub Head Code"
                            />
                          ) : (
                            item.head
                          )}
                        </td>
                        <td>
                          <Input
                            value={item.new_head || item.head}
                            onChange={({ target: { value } }) => {
                              handleTableChange("new_head", value, id);
                            }}
                            placeholder="New Head Code"
                          />
                        </td>
                        <td className="d-flex">
                          <Input
                            value={item.description}
                            onChange={({ target: { value } }) => {
                              handleTableChange("description", value, id);
                            }}
                            placeholder="Description"
                          />

                          <span
                            className="text-danger"
                            onClick={() => handleTableRemove(item)}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            X{" "}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <center>
                  <h5 className="text-dark">
                    There is no children on selected head
                  </h5>
                </center>
              )}
              {newData1.length && formType === "merge" && (
                <>
                  <Table size="small">
                    <thead>
                      <tr>
                        <th>Old Code</th>
                        <th>Enter New Code</th>
                        <th className="d-flex justify-content-between">
                          <div>Description</div>
                          <Button
                            color="success"
                            size="sm"
                            onClick={handleAdd1}
                          >
                            Add
                          </Button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {newData1?.map((item, id) => (
                        <tr key={id}>
                          <td>{item.head}</td>
                          <td>
                            <Input
                              value={item.new_head || item.head}
                              onChange={({ target: { value } }) => {
                                handleTableChange1("new_head", value, id);
                              }}
                              placeholder="New Head Code"
                            />
                          </td>
                          <td className="d-flex">
                            <Input
                              value={item.description}
                              onChange={({ target: { value } }) => {
                                handleTableChange1("description", value, id);
                              }}
                              placeholder="Description"
                            />

                            <span
                              className="text-danger"
                              onClick={() => handleTableRemove1(item)}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              X{" "}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default ChartOFAcctSetup;
