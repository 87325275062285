/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================


 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const buttonBase = {
  defaultProps: {
    disableRipple: false,
  },
};

export default buttonBase;
