// src/layouts/tables/ChartOFAcctSetup.js
import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CustomTypeahead from "components/CustomTypeahead";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { getSector } from "helper";
import { Button, Input, Label, Modal, ModalBody, Table } from "reactstrap";
import { year } from "helper";
import { _postApi } from "redux/action/api";
import MDTypography from "components/MDTypography";
import Tables from "layouts/tables";
import { getMDaType } from "helper";

const ChartOFAcctSetup = () => {
  const [formType, setFormType] = useState("split");
  const [mda, setMda] = useState([]);
  const [deactivated, setDeactivated] = useState([]);
  const [organization, setOrganization] = useState([]);

  const [levels, setLevel] = useState([]);
  const [subHead, setSubHead] = useState([]);

  const [form, setForm] = useState({
    type: "",
    sub_head: "",
    head: "",
    admin_dec: "",
    economic_code: "",
    economic_name: "",
    year: "",
    merge1: "",
    merge2: "",
    status: "new",
  });

  const getData = useCallback(() => {
    getMDaType({ type: form.type }, form.segment, setLevel, "level");
  }, [form.segment, form.type]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getSector({ type: "mdas" }, "all", (d) => {
      setMda(d);
    });
  }, []);
const getDeactivated=()=>{
     getSector({ type: "deactivated" }, "deactivated", (d) => {
      setDeactivated(d);
    });
}

  useEffect(() => {
   getDeactivated();
  }, []);

  useEffect(() => {
    getSector({ sub_head: form.head,type:form.merge2 }, "select_subhead", (d) => {
      let newData = d?.map((item) => ({
        ...item,
        status: "new",
      }));
      setSubHead(newData);
    });
  }, [form.head,form.merge2]);



  

  const handleTableChange = (name, value, index) => {
    // alert(name)
    let newArr = [];
    subHead?.forEach((item, i) => {
      if (i === index) {
        newArr.push({
          ...item,
          [name]: value,
        });
      } else {
        newArr.push(item);
      }
    });
    // console.log(name, value, index);
    setSubHead(newArr);
  };

  

  const { new_head, sub_head, new_desc, level } = form;
  let newData = subHead.filter((item) => item.status === "new");

     
  const handleSubmit = (e) => {
    e.preventDefault();
    getDeactivated()
    let data = subHead.map((it) => ({
      ...it,
      new_sub_head: form.new_head,
      new_description: it.description,
    }));
    _postApi(
      `/post-acc-setup?query_type=${formType}`,
      { data, form },
      (resp) => {
        if (resp.success) {
          alert("Success");
        }
      }
    );
  };

  const [toggle,setToggle]=useState(false)

  return (
    <>
       <Modal  isOpen={toggle} toggle={toggle} size="lg">
        <ModalBody>  
                          {newData.length ? (
                <>
                 {formType === "Reactivate" ?<h3>Activate children</h3>:""}
      
                <Table size="small" responsive>
                  <thead>
                    
                    <tr>
                      <th>Code</th>
                     
                      <th className="d-flex justify-content-between">
                        <div>Description</div>
                        
                      </th>
                        
                      
                    </tr>
                  </thead>
                  <tbody>

                    {
                         subHead?.map((item, id) => (
                      <tr key={id}>
                        <td>{item.head}</td>
                        <td>
                         {item.description}
                        </td>
                        <td className="d-flex">
                         {item.new_head}
                        </td>
                        <td>
                           {item.status==="activated"?item.status: <Button color="primary" onClick={()=>   handleTableChange("status", "activated", id)}>Activate</Button>}
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
                          </>
              ) : (
                <center>
                  <h5 className="text-dark">
                    There is no children on selected head
                  </h5>
                </center>
              )}

                 <center>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: 3 }}
                      >
                        Submit
                      </Button>
                    </center>
            </ModalBody>
       </Modal>

    <DashboardLayout>
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="success"
        borderRadius="lg"
        coloredShadow="info"
      >
        <center>
          <MDTypography variant="h2" color="white">
            <center>Reactivate ACcounts</center>
          </MDTypography>
        </center>
      </MDBox>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <Card sx={{ padding: 3 }}>
              {/* {JSON.stringify(subHead)}  */}
              <form onSubmit={handleSubmit}>
               
                {/* <Grid container spacing={2} sx={{ marginTop: 2 }}> */}
             
                
                       <>
                      
                
                        <Table responsive striped bordered>
<thead>
    <tr>
        <th style={{fontSize:15}}>Code</th>
        <th  style={{fontSize:15}}>Description</th>
        <th  style={{fontSize:15}}>Level</th>
        <th style={{fontSize:15}}>Sub Head</th>
        <th style={{fontSize:15}}>status</th>
        <th style={{fontSize:15}}>Action</th>
    </tr>

</thead>
<tbody>
    {deactivated.map((item, id) => <tr key={id}>   
        <td style={{fontSize:15}}>{item.head}</td>
        <td style={{fontSize:15}}>{item.description}</td>
        <td style={{fontSize:15}}>{item.level}</td>
        <td style={{fontSize:15}}>{item.sub_head}</td>
        <td style={{fontSize:15}}>{item.status}</td>
        <td><Button color="primary" 
            onClick={()=>{
                setForm({ ...form, head: item.head  })
                setToggle(!toggle); 
        }}
        >Reactivate</Button> </td>
    </tr>)}
    </tbody>
                       </Table >
                              </>
                 
                {/* </Grid> */}
              </form>
            </Card>
          </Grid>
       
    
        </Grid>
      </MDBox>
    </DashboardLayout>
        </>
  );
};

export default ChartOFAcctSetup;
