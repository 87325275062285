/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useRef, useState } from "react";
import Scrollbar from "components/Tree/Scrollbar";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import SearchBar from "components/SearchBar";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import { Input } from "reactstrap";
import { capitalizeWords } from "redux/action/api";
import { Spinner } from "reactstrap";
import { unflattenTable } from "redux/action/api";
import { ButtonGroup, Button, Modal } from "reactstrap";
import MDAReportModal from "./MDAReportsModal";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import { headerColors } from "redux/action/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { getNextYear } from "../getBudgetYear";

export function arrangeDates(dates) {
  // let actualDates = dates.filter(d => d.split(' ')[1] > 1)
  // Remove the first 3 elements (fixed columns) and sort the remaining dates

  const fDates = dates.map((a) => moment(a, "MMMM, YYYY").format("YYYYMMDD"));
  const sortedDates = dates
    .slice(3)
    .sort(
      (a, b) =>
        moment(a, "MMMM, YYYY").format("YYYYMMDD") -
        moment(b, "MMMM, YYYY").format("YYYYMMDD")
    );
  const sortedFDates = fDates.slice(3).sort((a, b) => a - b);
  console.log(sortedDates);
  console.log(sortedFDates);

  // Concatenate the fixed columns with the sorted dates
  const arrangedDates = dates.slice(0, 3).concat(sortedDates);

  return arrangedDates;
}

export default function ReportsRevByFund({
  title = "Total Revenue by Fund",
  route = "/get-report",
  options = [],
  type = "",
}) {
  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
  });
  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);
  const years = useYear();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: type,
    title,
  });

  const newFilter = (item) => {
    return item.approve_amount === 0;
  };

  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const likeVar = selectedOption.title.includes("Personnel")
    ? "Personnel"
    : selectedOption.title.includes("Capital")
    ? "Capital"
    : selectedOption.title.includes("Other NDR")
    ? "02%"
    : selectedOption.title.includes("Debt")
    ? "01%"
    : null;

  const toggle = () => setModal(!modal);
  const toggle1 = () => setDropdownOpen(!dropdownOpen);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}&budget_year=${form.budget_year}&like=${likeVar}`,
      (data) => {
        setLoading(false);
        // const ppp = data?.results?.filter((p) => p.sub_head?.startsWith("2"));
        const tt = unflattenTable(data?.results);
        setTreeData(tt);
        console.log("data?.results", tt);
      },
      (err) => console.log(err)
    );
  }, [form.budget_year, selectedOption.value, likeVar]);

  const sumChildren = (node, columnName) => {
    if (!node.children || node.children.length === 0) {
      return node[columnName];
    } else {
      let sum = node[columnName] || 0;
      for (const child of node.children) {
        sum += sumChildren(child);
      }
      return sum;
    }
  };

  const calculateTotal = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.approve_amount;
    } else {
      let sum = node.approve_amount || 0;
      for (const child of node.children) {
        sum += calculateTotal(child);
      }
      return sum;
    }
  };

  // const headerColors = ["#666", "#B6B6B4", "##DCDCDC", "#f1f1f1"];
  const newFilterHead = (item) => {
    return calculateTotal(item) === 0;
  };

  const generateTable2 = () => {
    const renderItems = (item, index) => {
      const isTitle = item.code === "1000000000000000000";
      return (
        <React.Fragment key={`group_${index}`}>
          {newFilterHead(item) ? null : (
            <tr
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                toggle();
                setSelectMDA(item);
                console.log(item);
              }}
            >
              <td
                style={{
                  backgroundColor: headerColors[item.parentHeads?.length],
                  color: isTitle ? "white" : "black",
                }}
              >
                {item.description === "FUND SEGMENT"
                  ? ""
                  : item.code.replace(/0+$/, "")}
              </td>
              <td
                style={{
                  backgroundColor: headerColors[item.parentHeads?.length],
                  color: isTitle ? "white" : "black",
                  textDecoration: isTitle ? "underline" : "none",
                }}
              >
                {item.description === "FUND SEGMENT" &&
                selectedOption.title === "Total Revenue by Fund"
                  ? "Total Revenue (including Capital Receipts, excluding Open Balance)"
                  : item.description === "FUND SEGMENT"
                  ? selectedOption.title
                  : item.description}
              </td>

              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads?.length],
                  color: isTitle ? "white" : "black",
                  textDecoration: isTitle ? "underline" : "none",
                }}
              >
                {" "}
                {isNaN(calculateTotal(item)) || 0
                  ? "---"
                  : formatNumber(calculateTotal(item))}
              </td>
            </tr>
          )}

          {item.children?.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else if (newFilter(child)) {
              return null;
            } else {
              return (
                <tr key={`row_${index}_${idx}`} style={{ fontSize: "12px" }}>
                  <td>{child.code}</td>
                  <td>{child.description}</td>

                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.approve_amount) || 0
                      ? "---"
                      : formatNumber(child.approve_amount)}
                  </td>
                </tr>
              );
            }
          })}
        </React.Fragment>
      );
    };

    return (
      <table className="table table-bordered">
        <thead>
          <tr
            style={{
              fontSize: "14px",
              borderWidth: 1,
              borderColor: "black",
              textAlign: "center",
              backgroundColor: "orange",
            }}
          >
            <th style={{ backgroundColor: "orange" }}>Code</th>
            <th style={{ backgroundColor: "orange" }}>Description</th>
            <th style={{ backgroundColor: "orange" }}>{`${parseInt(
              form.budget_year
            )} Approved Budget`}</th>
          </tr>
        </thead>
        <tbody>{treeData?.map((item, i) => renderItems(item, i))}</tbody>
        {/* {JSON.stringify(treeData.length)} */}
      </table>
    );
  };

  const tableRef = useRef();
  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <div>
              <Modal
                isOpen={modal}
                toggle={toggle}
                size="lg"
                style={{
                  marginLeft: "30%",
                }}
              >
                <MDAReportModal
                  toggle={toggle}
                  mda_name={selectMDA.description}
                />
              </Modal>
            </div>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {capitalizeWords(selectedOption.title)}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* {JSON.stringify({ results })} */}
                <div
                  className="d-flex justify-content-between m-1 row"
                  style={{ marginRight: 10, marginLeft: 10 }}
                >
                  <div className="col-sm-2 mb-2">
                    <Input
                      type="select"
                      name="budget_year"
                      value={form.budget_year}
                      onChange={handleChange}
                    >
                      <option>--select--</option>
                      {years?.map((item) => (
                        <option key={item}>{item}</option>
                      ))}
                    </Input>
                  </div>
                  <div className="col-sm-8 mb-2">
                    <div className="text-center"></div>
                    <center>
                      <ButtonGroup>
                        {options.map((item) => (
                          <Button
                            // style={{ lineHeight: "12px", width: "10ch" }}
                            size="sm"
                            key={item.title}
                            outline={
                              selectedOption.title !== item.title ? true : false
                            }
                            color={
                              selectedOption.title === item.title
                                ? "primary"
                                : "secondary"
                            }
                            onClick={() =>
                              handleButtonClick(item.value, item.title)
                            }
                          >
                            <small>{item.title}</small>
                          </Button>
                        ))}
                      </ButtonGroup>
                    </center>
                  </div>
                  <div className="col-sm-2 mb-2">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle1}>
                      <DropdownToggle
                        className="btn btn-primary text-white mb-3 ml-3"
                        caret
                      >
                        <FaFileDownload
                          color="white"
                          size="1.2rem"
                          className="mr-5"
                        />
                        Downloads
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => console.log("Option 1 clicked")}
                        >
                          <DownloadTableExcel
                            filename={selectedOption.title}
                            sheet={selectedOption.title}
                            currentTableRef={tableRef.current}
                          >
                            {/* <Button
                            style={{ color: "white" }}
                            className="text-white mb-3 ml-3"
                            size="sm"
                            color="primary"
                          > */}{" "}
                            {/* <FaFileDownload color="white" size="1.2rem" />{" "} */}
                            Download to Excel
                            {/* </Button> */}
                          </DownloadTableExcel>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => console.log("Option 2 clicked")}
                        >
                          PDF
                        </DropdownItem>
                        <DropdownItem />
                        <DropdownItem
                          onClick={() => console.log("Another option clicked")}
                        >
                          Word
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div className="p-3">
                  {loading ? (
                    <div className="loading-container text-center">
                      <Spinner color="primary" style={{ marginTop: "20px" }} />{" "}
                      Loading...
                    </div>
                  ) : (
                    <Scrollbar>{generateTable2()}</Scrollbar>
                  )}
                </div>
              </MDBox>
              {/* {JSON.stringify(treeData)} */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
