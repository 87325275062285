/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { _postApi } from "redux/action/api";
import { Label, Input } from "reactstrap";
import { useYear } from "useYearList";
import { formatNumber } from "redux/action/api";

export default function PreviousCeiling({
  status = "sector",
  subhead = null,
  year,
}) {
  const [previousYr, setPreviousYr] = useState([]);
  const [fetchloading, setFetchLoading] = useState(false);
  const years = useYear();
  const [form, setForm] = useState({
    description: "Administrative Segment",
    year: year || moment().format("YYYY"),
  });

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const getPrevCellingData = useCallback(() => {
    setFetchLoading(true);
    _postApi(
      `/budgetCeiling?query_type=previous-yr`,
      { status, year: form.year, subhead },
      (data) => {
        setPreviousYr(data.results);
        setFetchLoading(false);
      },
      (err) => {
        console.log(err);
        setFetchLoading(false);
      }
    );
  }, [form.year, status]);

  useEffect(() => {
    getPrevCellingData();
  }, [getPrevCellingData]);

  const total =
    previousYr?.reduce((a, b) => a + parseFloat(b?.total_amt), 0) || 0;

  return (
    <div className="card card-body">
      <div className="row">
        <div className="col-md-4">
          <p className="text-right">
            <b>Total: {formatNumber(total)}</b>
          </p>
        </div>
        <div className="col-md-4" />
        <div className="col-md-4">
          <Label>Select year</Label>
          <Input
            type="select"
            name="year"
            value={form.year}
            onChange={handleChange}
          >
            <option>--select--</option>
            {years?.map((item) => (
              <option key={item}>{item}</option>
            ))}
          </Input>
        </div>
        {fetchloading && <div>loading...</div>}
        {previousYr.map((item) => (
          <div className="col-md-4 p-2" key={item.head}>
            <div>
              <b>{item.description}</b>
            </div>
            <div>{item.head}</div>
            <div>{item.amt}%</div>
            <div>₦{formatNumber(item.total_amt)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

